<template>
  <div class="main-boards__content-yourBoards">
    <!-- AQUI VA ISC_BOARDS -->
    <div class="main-boards__content_grid">
      <div class="main-boards__content_grid_item bg-white">
        <v-row>
          <v-col lg="3" md="4" sm="6" v-for="board in accountBoards" :key="board.id">
            <v-card>
              <v-card-title class="text-center d-block font15pt mb-2">
                <span class="font-weight-bold">{{ board.name }}</span>
                <span class="ml-1" v-if="board.tw_key_count.count !== 0 && board.tw_key_count.active == false || board.fb_key_count.count !== 0 && board.fb_key_count.active == false || board.ig_key_count.count !== 0 && board.ig_key_count.active == false || board.lk_key_count.count !== 0 && board.lk_key_count.active == false || board.nws_key_count.count !== 0 && board.nws_key_count.active == false"> 
                  <tooltipComponent :tooltipText="tooltip" />
                </span>
              </v-card-title>
              <!-- color-base: #f3f4f7 -->
              <div class="
                  d-flex
                  justify-center
                  align-center
                  main-boards__content-yourBoards__socialicons
                ">
                <div class="position-relative" :class="{ tw_color: board.tw_key_count.active == true }"
                  v-if="board.tw_key_count.count !== 0">
                  <span class="
                      position-absolute
                      alerticon
                      text-center
                      font-weight-bold
                    ">
                    {{ board.tw_key_count.count }}
                  </span>
                  <v-icon size="24">fab fa-twitter</v-icon>
                </div>
                <div class="position-relative ml-3" :class="{ fb_color: board.fb_key_count.active == true }"
                  v-if="board.fb_key_count.count !== 0">
                  <span class="
                      position-absolute
                      alerticon
                      text-center
                      font-weight-bold
                    ">
                    {{ board.fb_key_count.count }}
                  </span>
                  <v-icon size="24">fab fa-facebook-square</v-icon>
                </div>
                <div class="position-relative ml-3" :class="{ ig_color: board.ig_key_count.active == true }"
                  v-if="board.ig_key_count.count !== 0">
                  <span class="
                      position-absolute
                      alerticon
                      text-center
                      font-weight-bold
                    ">
                    {{ board.ig_key_count.count }}
                  </span>
                  <!-- <v-icon size="26">fab fa-twitter</v-icon> -->
                  <v-icon size="24">fab fa-instagram</v-icon>
                </div>
                <div class="position-relative ml-3" :class="{ lk_color: board.lk_key_count.active == true }"
                  v-if="board.lk_key_count.count !== 0">
                  <span class="
                      position-absolute
                      alerticon
                      text-center
                      font-weight-bold
                    ">
                    {{ board.lk_key_count.count }}
                  </span>
                  <v-icon size="24">fab fa-linkedin</v-icon>
                </div>
                <div class="position-relative ml-3" :class="{ nws_color: board.nws_key_count.active == true }"
                  v-if="board.nws_key_count.count !== 0">
                  <span class="
                      position-absolute
                      alerticon
                      text-center
                      font-weight-bold
                    ">
                    {{ board.nws_key_count.count }}
                  </span>
                  <v-icon size="24">fa-solid fa-newspaper</v-icon>
                </div>
              </div>
              <div class="main-boards__content-yourBoards__gauge">
                <vue-gauge if="board.dashboard_metrics.sentiment > 0" class="text-center" :refid="`id_${board.id}`"
                  :options="board.gaugeOptions">
                </vue-gauge>
              </div>
              <div class="main-boards__content-yourBoards__info pl-1 pr-1">
                <div class="
                    main-boards__content-yourBoards__info-value
                    text-center
                    mb-3
                  ">
                  <span class="font15pt titleColor font-weight-light">
                    {{
                      Math.round(
                        getMaxSentiment(board.dashboard_metrics.sentiment)
                      )
                    }}%
                  </span>
                </div>
                <div class="
                    d-flex
                    main-boards__content-yourBoards__info__boxsentiment
                    pl-3
                    pr-3
                  ">
                  <div class="
                      pa-0
                      bgColorpositive
                      main-boards__content-yourBoards__info__boxsentiment-item
                    " :style="{
                          width:
                            convertDecimalToPercent(
                              board.dashboard_metrics.sentiment.pos
                            ) + '% !important',
                        }"></div>
                  <div class="
                      pa-0
                      bgColorneutral
                      main-boards__content-yourBoards__info__boxsentiment-item
                    " :style="{
                      width:
                        convertDecimalToPercent(
                          board.dashboard_metrics.sentiment.neu
                        ) + '% !important',
                    }"></div>
                  <div class="
                      pa-0
                      bgColornegative
                      main-boards__content-yourBoards__info__boxsentiment-item
                    " :style="{
                      width:
                        convertDecimalToPercent(
                          board.dashboard_metrics.sentiment.neg
                        ) + '% !important',
                    }"></div>
                </div>
                <div class="
                    d-flex
                    main-boards__content-yourBoards__info__boxvalues
                    pl-3
                    pr-3
                  ">
                  <div class="col pt-1 pb-2 text-center">
                    <span class="font10pt leyendColor">
                      {{
                        Math.round(
                          convertDecimalToPercent(
                            board.dashboard_metrics.sentiment.pos
                          )
                        )
                      }}%
                    </span>
                  </div>
                  <div class="col pt-1 pb-2 text-center">
                    <span class="font10pt leyendColor">
                      {{
                        Math.round(
                          convertDecimalToPercent(
                            board.dashboard_metrics.sentiment.neu
                          )
                        )
                      }}%
                    </span>
                  </div>
                  <div class="col pt-1 pb-2 text-center">
                    <span class="font10pt leyendColor">
                      {{
                        Math.round(
                          convertDecimalToPercent(
                            board.dashboard_metrics.sentiment.neg
                          )
                        )
                      }}%
                    </span>
                  </div>
                </div>
                <div class="
                    grid-item__content--values
                    text-center
                    d-flex
                    flex-column
                    pt-3
                    pb-0
                    bgGrapg
                  ">
                  <span class="
                      grid-item__content--values--days
                      font10pt
                      text-capitalize
                      white--text
                      font-weight-bold
                    ">last 15 days</span>
                  <span class="
                      grid-item__content--values--count
                      text-capitalize
                      font37pt
                      font-weight-bold
                      white--text
                      font-weight-bold
                    ">
                    {{
                      convertNumberTokFormat(
                        board.dashboard_metrics.count_samples
                      )
                    }}</span>
                  <span class="
                      grid-item__content--values--type
                      text-capitalize
                      font15pt
                      white--text
                      font-weight-bold
                    ">Samples</span>
                  <div class="grid-item__content--values__graph px-2" style="min-height: 100px;">
                    <apexchart v-if="convertNumberTokFormat(
                          board.dashboard_metrics.count_samples
                        ) !== 0
                        " type="area" height="100" :options="chartOptions" :series="[
      {
        name: 'samples',
        data: board.dashboard_metrics.histogram,
      },
    ]">
                    </apexchart>
                  </div>
                </div>
              </div>
              <div class="d-flex main-boards__content-yourBoards__buttoms">
                <div class="col-4 pa-1">
                  <button class="button-overview bgColorBase" @click="toOverview(board.id)">
                    <v-icon size="18">fas fa-eye</v-icon>
                  </button>
                </div>
                <div class="col-4 pa-1">
                  <button class="button-overview bgColorBase" @click="updateContextModal(board.id)">
                    <v-icon size="18">fa fa-edit</v-icon>
                  </button>
                </div>
                <div class="col-4 pa-1">
                  <button class="button-overview bgColorBase" @click="contextToDelete = board.id">
                    <v-icon size="18">fa fa-trash</v-icon>
                  </button>
                </div>
              </div>
            </v-card>
          </v-col>
        </v-row>
      </div>
    </div>
    <UpdateContext :contextData="contextData" v-if="updateContextModalOpen" @close="updateContextModalOpen = false"
      @refreshData="refreshData" />
    <v-overlay :value="overlay">
      <v-progress-circular indeterminate size="64"></v-progress-circular>
    </v-overlay>
    <div class="modal-mask" v-if="contextToDelete">
      <div class="modal-wrapper">
        <div class="modal-container w-50">
          <v-row>
            <v-col>
              <h1 class="font18pt titleColor font-weight-bold">
                Are you sure you want to delete "{{
                  accountBoards.find((board) => board.id == contextToDelete)
                    .name
                }}"?
              </h1>
            </v-col>
          </v-row>
          <v-row>
            <v-col class="text-right">
              <button class="bgColorPrincipal pt-2 pb-2 pl-5 pr-5 ml-3 rounded" @click="contextToDelete = null"
                :disabled="loadingDelete">
                <span class="mr-1 font13pt white--text">No</span>
              </button>
              <button class="bgColorPrincipal pt-2 pb-2 pl-5 pr-5 ml-3 rounded" @click="deleteContext()"
                :disabled="loadingDelete">
                <span class="mr-1 font13pt white--text">{{
                  loadingDelete ? "Deleting..." : "Yes"
                }}</span>
              </button>
            </v-col>
          </v-row>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import UpdateContext from "@/components/context/UpdateContext";
import tooltipComponent from "@/components/base/tooltip.vue";
import { mapActions, mapGetters } from "vuex";
export default {
  components: { UpdateContext, tooltipComponent },
  props: ["contextCreated"],
  data() {
    return {
      title: "TOOO",
      tooltip: "The gray icon means that all the drives for that social network are disabled.",
      yourBoards: true,
      yourBoardsItems: null,
      chartOptions: {
        chart: {
          type: "area",
          height: 50,
          width: "100%",
          sparkline: {
            enabled: true,
          },
          zoom: {
            enabled: false,
          },
          toolbar: {
            show: false,
          },
        },
        grid: {
          show: false,
        },
        dataLabels: {
          enabled: false,
        },
        stroke: {
          curve: "smooth",
          width: 1,
        },
        theme: {
          mode: "light",
          monochrome: {
            enabled: true,
            color: "#FFFFFF",
            shadeTo: "light",
            shadeIntensity: 0.65,
          },
        },
        xaxis: {
          labels: {
            show: false,
          },
          axisTicks: {
            show: false,
          },
          tooltip: {
            enabled: false,
          },
        },
        yaxis: {
          labels: {
            show: false,
          },
          opposite: true,
          showAlways: false,
        },
        legend: {
          horizontalAlign: "left",
        },
      },
      chartOptionsIscBoards: {
        chart: {
          type: "area",
          height: 100,
          width: "100%",
          zoom: {
            enabled: false,
          },
          toolbar: {
            show: false,
          },
        },
        grid: {
          show: false,
        },
        dataLabels: {
          enabled: false,
        },
        stroke: {
          curve: "smooth",
          width: 1,
        },
        theme: {
          mode: "light",
          monochrome: {
            enabled: true,
            color: "#00a5ff",
            shadeTo: "light",
            shadeIntensity: 0.65,
          },
        },
        xaxis: {
          labels: {
            show: false,
          },
          axisTicks: {
            show: false,
          },
          tooltip: {
            enabled: false,
          },
        },
        yaxis: {
          labels: {
            show: false,
          },
          opposite: true,
          showAlways: false,
        },
        legend: {
          horizontalAlign: "left",
        },
      },
      loading: true,
      updateContextModalOpen: false,
      accountBoards: [],
      overlay: false,
      contextToDelete: null,
      loadingDelete: false,
    };
  },
  computed: {
    /* ...mapGetters("dashboard", {
      over: "overviews",
    }), */
    ...mapGetters("global", {
      yourBoard: "getYourBoard",
    }),
    /* items() {
      return this.over;
    }, */
  },
  mounted() {
    this.changeYourBoard(true);
  },
  async created() {
    this.removeContextBoard();
    await this.refreshData();
    this.disabledMenuItem(true);
  },
  methods: {
    //...mapActions("dashboard", ["overviews"]),
    ...mapActions("global", [
      "setContextBoard",
      "removeContextBoard",
      "changeYourBoard",
      "disabledMenuItem"
    ]),
    convertNumberTokFormat(num) {
      return Math.abs(num) > 999
        ? Math.sign(num) * (Math.abs(num) / 1000).toFixed(1) + "k"
        : Math.sign(num) * Math.abs(num);
    },
    async updateContextModal(id) {
      this.activeOverlay(true);
      const {
        data: { getContextById },
      } = await this.$store.dispatch("dashboard/getContextById", {
        context_id: id,
      });
      this.updateContextModalOpen = true;
      this.contextData = getContextById;
      this.activeOverlay(false);
    },
    async deleteContext() {
      this.loadingDelete = true;
      const id = this.contextToDelete;
      try {
        const {
          data: { deleteContext },
        } = await this.$store.dispatch("dashboard/deleteContext", {
          context_id: id,
        });
        if (deleteContext == "Contexto eliminado") {
          this.accountBoards.splice(
            this.accountBoards.findIndex((board) => board.id == id),
            1
          );
        }
      } catch (error) {
        console.error(error);
      } finally {
        this.loadingDelete = false;
        this.contextToDelete = null;
      }
    },
    getMaxSentiment(sentiment) {
      const neg = sentiment.neg || 0;
      const neu = sentiment.neu || 0;
      const pos = sentiment.pos || 0;
      return Math.max.apply(null, [neg, neu, pos]) * 100;
    },
    convertDecimalToPercent(currentNumber) {
      return currentNumber ? currentNumber * 100 : 0;
    },
    toOverview(context) {
      //console.log(context);
      this.$router.push("/overview/" + context);
      this.setContextBoard(context);
    },
    setDataGauge(data) {
      const baseColor = "#f3f4f7";
      const positiveColor = "#9fd895";
      const neutralColor = "#828a96";
      const negativeColor = "#ff7e7e";
      let items;
      if (data) {
        items = data.filter((i) => i.dashboard_metrics);
        items.map((x) => {
          if (x.dashboard_metrics) {
            let sentimentMax = this.getMaxSentiment(
              x.dashboard_metrics.sentiment
            );
            let arcValue =
              sentimentMax == 0 ? (arcValue = 1) : (arcValue = sentimentMax);
            let sentimentColorGauge;
            let sentimentData = [
              {
                name: "positive",
                value: this.convertDecimalToPercent(
                  x.dashboard_metrics.sentiment.pos
                ),
                color: positiveColor,
              },
              {
                name: "neutral",
                value: this.convertDecimalToPercent(
                  x.dashboard_metrics.sentiment.neu
                ),
                color: neutralColor,
              },
              {
                name: "negative",
                value: this.convertDecimalToPercent(
                  x.dashboard_metrics.sentiment.neg
                ),
                color: negativeColor,
              },
            ];
            let fillSentimentData = sentimentData.find(
              (x) => x.value == sentimentMax
            );
            sentimentColorGauge =
              sentimentMax == 0
                ? (sentimentColorGauge = baseColor)
                : (sentimentColorGauge = fillSentimentData.color);
            /*console.log('filter', sentimentData)*/
            x.sentimentData = sentimentData;
            let gaugeOptionsSet = {
              needleValue: sentimentMax,
              arcDelimiters: [arcValue == 100 ? 99 : arcValue],
              ahsNeedle: true,
              needleColor: "black",
              rangeLabel: "",
              arcPadding: 1,
              arcColors: [sentimentColorGauge, baseColor],
            };
            x.gaugeOptions = gaugeOptionsSet;
          }
        });
        return items;
      }
    },
    activeOverlay(status) {
      this.overlay = status;
    },
    async refreshData() {
      /* this.overviews("ac4a5106-3b2e-4c9e-996f-988ff6fdcc65"); */
      this.activeOverlay(true);
      const {
        data: { getContextsByAccount },
      } = await this.$store.dispatch("dashboard/getContextsByAccount", {
        account_id: sessionStorage.getItem("client_id"),
      });
      const infoContexts = this.setDataGauge(getContextsByAccount);
      infoContexts.map((e) => {
        const fb = e.keywords.filter((element) => element.type == "fb");
        const tw = e.keywords.filter((element) => element.type == "tw");
        const ig = e.keywords.filter((element) => element.type == "ig");
        const lk = e.keywords.filter((element) => element.type == "lk");
        const nws = e.keywords.filter((element) => element.type == "nws");
        const fb_active = fb.some(d => d.active === true);
        const tw_active = tw.some(d => d.active === true);
        const ig_active = ig.some(d => d.active === true);
        const lk_active = lk.some(d => d.active === true);
        const nws_active = nws.some(d => d.active === true);
        e.fb_key_count = {
          count: fb.length,
          active: fb_active
        }
        e.tw_key_count = {
          count: tw.length,
          active: tw_active
        }
        e.ig_key_count = {
          count: ig.length,
          active: ig_active
        }
        e.lk_key_count = {
          count: lk.length,
          active: lk_active
        }
        e.nws_key_count = {
          count: nws.length,
          active: nws_active
        }
      })
      this.accountBoards = infoContexts;
      this.$forceUpdate();
      this.activeOverlay(false);
    },
  },
  watch: {
    contextCreated: {
      immediate: true,
      handler: function (newValue, oldValue) {
        if (newValue === true) {
          this.refreshData();
          this.$emit("updateContextCreated", false);
        }
      },
    },
  },
};
</script>
