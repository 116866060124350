<template>
	<div class="context-box" style="width: 90%;">
		<v-row no-gutters>
			<v-col :sm="12" :md="12" :lg="12">
				<v-row no-gutters>
					<v-col cols="12"><span class="font12pt leyendColor">What:</span></v-col>
				</v-row>
			</v-col>
			<v-col :sm="6" :md="9" :lg="10">
				<v-row>
					<v-col class="pr-1 pl-1 pt-1">
						<v-select
							label="Country"
							:disabled="allNewspapers.length == 0"
							:items="nwsCountries"
							v-model="nwsCountrySelected"
							:loading="allNewspapers.length == 0"
							hide-details
							@change="changeSelectPlace(nwsCountrySelected)"
						></v-select>
					</v-col>
					<v-col class="pr-1 pl-1 pt-1">
						<v-select
							label="Newspaper"
							:disabled="nwsCountrySelected == ''"
							:items="nwsNames"
							v-model="nwsNameSelected"
							:loading="allNewspapers.length == 0"
							hide-details
							@change="changeSelectName(nwsNameSelected)"
						></v-select>
					</v-col>
					<v-col class="pr-1 pl-1 pt-1">
						<v-select
							label="Section"
							:disabled="nwsNameSelected == ''"
							:items="nwsUrls"
							:loading="allNewspapers.length == 0"
							hide-details
							v-model="nwsUrlSelected"
							@change="changeSelectUrl(nwsUrlSelected)"
						></v-select>
					</v-col>
				</v-row>
				<v-row no-gutters>
					<v-col class="pr-1 pl-1">
						<div class=" textError ml-0" v-if="warningText != ''">
							<span
								><v-icon size="12" color="red">fa fa-exclamation-circle</v-icon> {{ warningText }}</span
							>
						</div>
					</v-col>
				</v-row>
			</v-col>
			<v-col>
				<div class="d-flex justify-end align-end">
					<v-btn
						depressed
						color="bgColorPrincipal"
						class="mainBtn mx-2 mt-2"
						:disabled="nwsToAdd === ''"
						@click="addNewDriver()"
					>
						<span class="white--text">Add</span>
					</v-btn>
				</div>
			</v-col>
			<v-row class="context-box__table">
				<v-col>
					<div class="context-box__table__header d-flex">
						<div class="col-12">
							<span class="font12pt leyendColor">Newspaper list</span>
						</div>
					</div>
					<div class="bgColorBase context-box__table__body">
						<v-virtual-scroll :bench="10" :items="drivers" max-height="168" item-height="42">
							<template v-slot:default="{ item }">
								<v-row
									v-bind:style="{
										// backgroundColor: item.geo_dashboard ? '#fbfbfb' : 'inherit',
									}"
								>
									<v-col cols="1" align="end">
										<div>
											<v-tooltip top>
												<template v-slot:activator="{ on, attrs }">
													<v-icon
														v-if="item.geo_dashboard"
														size="14"
														color="#a2acc4"
														v-bind="attrs"
														v-on="on"
														>fas fa-globe</v-icon
													>
												</template>
												<span>ISC Driver</span>
											</v-tooltip>
										</div>
									</v-col>
									<v-col cols="5">
										<div
											style="
                      overflow: hidden;
                      white-space: nowrap;
                      text-overflow: ellipsis;
                    "
										>
											<span class="font12pt titleColor ml-3">
												{{ item.newspaper_name }}
											</span>
										</div>
									</v-col>
									<v-col cols="2" align="start">
										<div
											style="
                      overflow: hidden;
                      white-space: nowrap;
                      text-overflow: ellipsis;
                    "
										>
											<span class="font12pt leyendColor">
												{{
													`${
														item.page_url.split("/").slice(-1) == ""
															? item.page_url
																	.split("/")
																	.slice(-2, -1)
																	.toString()
															: item.page_url
																	.split("/")
																	.slice(-1)
																	.toString()
													}`
												}}
											</span>
										</div>
									</v-col>
									<v-col> </v-col>
									<v-col cols="1" align="start">
										<div>
											<v-icon @click="deleteDriver(item)" size="11" color="#a2acc4"
												>fas fa-trash</v-icon
											>
										</div>
									</v-col>
									<v-col cols="1" align="start">
										<!-- <div>
                      <v-icon :id="`active_${item.id}`" class="d-inline-block" size="12" color="#00a5ff"
                        @click="changeStatus(item, true)">fas
                        fa-eye</v-icon>
                      <v-icon :id="`inactive_${item.id}`" class="d-none" size="12" color="#a2acc4"
                        @click="changeStatus(item, false)">fas
                        fa-eye</v-icon>
                    </div> -->
										<div>
											<v-icon
												v-if="item.active === true"
												size="12"
												color="#00a5ff"
												@click="toggleActive(item, false)"
											>
												fas fa-eye</v-icon
											>
											<v-icon
												v-if="item.active === false"
												size="12"
												color="#a2acc4"
												@click="toggleActive(item, true)"
											>
												fas fa-eye</v-icon
											>
										</div>
									</v-col>
								</v-row>
							</template>
						</v-virtual-scroll>
					</div>
				</v-col>
			</v-row>
		</v-row>
	</div>
</template>

<script>
import AddIscDriverModal from "@/components/context/AddIscDriverModal.vue";
export default {
	name: "UpdateNewsPaperDrivers",
	components: { AddIscDriverModal },
	props: ["formContext"],
	data() {
		return {
			allNewspapers: [],
			nwsCountries: [],
			nwsNames: [],
			nwsUrls: [],
			nwsCountrySelected: "",
			nwsNameSelected: "",
			nwsUrlSelected: "",
			nwsToAdd: "",
			warningText: "",
			drivers: [],
			paises: [
				// {
				//   id: "all",
				//   name: "All (Around the world)",
				// },
				{
					id: "2dfa9ecb0179a4e4",
					name: "Perú",
				},
				{
					id: "47a3cf27863714de",
					name: "Chile",
				},
			],

			/* nwsPlaceSelected: "",
      nwsNameSelected: "",
      nwsSelected: "",
      firstFilterDisbled: true,
      newspaperAdd: '',
      newspaperUrlAdd: '',
      newspaperPlaceAdd: '',
      allNewspapersLoading: false,
      nwsDrivers: [],
      allNewspaperLoad: [],
      newspaper: [],
      newspaperUrl: [],
      newspaperPlace: [],
      newDriverPage: "",
      openModal: false,
      selectedISCDrivers: [],
      buttonDisabled: true, */
		};
	},
	async created() {
		await this.getNewspaperList();
		this.drivers = this.formContext.newspaperKeywords;
	},
	methods: {
		/* changeStatus(item, e) {
      let id__active = document.getElementById(`active_${item.id}`);
      let id__inactive = document.getElementById(`inactive_${item.id}`);
      if (e) {
        item.active = false;
        id__active.classList.remove('d-inline-block');
        id__active.classList.add('d-none');
        id__inactive.classList.add('d-inline-block');
      } else {
        item.active = true;
        id__inactive.classList.remove('d-inline-block');
        id__inactive.classList.add('d-none');
        id__active.classList.add('d-inline-block');
      }
    }, */
		async toggleActive(item, status) {
			this.$emit("updateLoading", true);
			if (item.id) {
				const variables = {
					context_id: this.formContext.contextId,
					keyword_id: item.id,
					type: "nws",
					active: status,
				};
				try {
					const {
						data: { toggleActiveKeyword },
					} = await this.$store.dispatch("dashboard/toggleActiveKeyword", variables);
					if (toggleActiveKeyword[0].active === status) {
						this.drivers = this.drivers.map(function(k) {
							if (k.id == item.id) {
								k.active = status;
							}
							return k;
						});
					}
				} catch (error) {
					console.error(error);
				} finally {
					this.$emit("updateLoading", false);
				}
			}
		},
		changeSelectPlace(countryName) {
			this.nwsToAdd = "";
			this.nwsNameSelected = "";
			this.nwsUrlSelected = "";
			this.nwsCountrySelected = countryName;
			this.nwsNames = this.allNewspapers
				.filter((n) => {
					if (n.place_id == this.getPlaceId(this.nwsCountrySelected)) return n;
				})
				.map((e) => e.newspaper_name);
		},
		changeSelectName(newspaperName) {
			this.nwsToAdd = "";
			this.nwsUrlSelected = "";
			this.nwsNameSelected = newspaperName;
			this.nwsUrls = this.allNewspapers
				.filter((n) => {
					if (
						n.place_id == this.getPlaceId(this.nwsCountrySelected) &&
						n.newspaper_name == this.nwsNameSelected
					)
						return n;
				})
				.map((e) => e.page_url);
		},
		changeSelectUrl(newspaperUrl) {
			this.nwsUrlSelected = newspaperUrl;
			this.nwsToAdd = this.allNewspapers.filter((n) => {
				if (
					n.place_id == this.getPlaceId(this.nwsCountrySelected) &&
					n.newspaper_name == this.nwsNameSelected &&
					n.page_url == this.nwsUrlSelected
				)
					return n;
			})[0];
		},
		getPlaceId(countryName) {
			return this.paises.find((p) => {
				if (p.name == countryName) return p.id;
			}).id;
		},
		async getNewspaperList() {
			try {
				this.allNewspapers = [];
				const {
					data: { getNewspapersDrivers },
				} = await this.$store.dispatch("dashboard/getNewsPaperList");
				if (getNewspapersDrivers) {
					this.allNewspapers = getNewspapersDrivers;
					this.fillSelects();
				} else {
					throw new Error("Error getting newspaper list, please try again later.");
				}
				/* this.allNewspapersLoading = true;
        this.buttonDisabled = true;
        this.firstFilterDisbled = true;

        const {
          data: { getNewspapersDrivers },
        } = await this.$store.dispatch("dashboard/getNewsPaperList");
        if (getNewspapersDrivers) {
          this.allNewspaperLoad = getNewspapersDrivers;
          this.fillSelects();
          this.allNewspapersLoading = false;
        } else {
          throw new Error("Error al validar diario");
        } */
			} catch (error) {
				this.allNewspapers = [];
				console.error(error);
				throw error;
			}
		},
		fillSelects() {
			const nwsPlacesTemp = [];
			this.allNewspapers.map((d) => {
				nwsPlacesTemp.push(d.place_id);
				this.nwsNames.push(d.newspaper_name);
				this.nwsUrls.push(d.page_url);
			});
			nwsPlacesTemp.map((place) => {
				const pais = this.paises.find((p) => {
					if (p.id == place && p.name) return p.name;
				});
				this.nwsCountries.push(pais.name);
			});
		},
		async addNewDriver() {
			try {
				if (this.nwsToAdd != "") {
					this.$emit("updateLoading", true);
					const driverToAdd = Object.assign({}, this.nwsToAdd);
					this.cleanSelects();
					const body = {
						context_id: this.formContext.contextId,
						type: "nws",
						active: true,
						driver: {
							id: driverToAdd.id,
						},
					};
					if (this.drivers.length > 0) {
						const driverExist = this.drivers.find((d) => d.id == driverToAdd.id);
						if (driverExist) {
							this.showWarning("Newspaper already added.");
							this.$emit("updateLoading", false);
							return;
						}
					}
					const response = await this.$store.dispatch("dashboard/addKeyword", body);
					if (response.data.addKeyword.id) {
						this.drivers.push(response.data.addKeyword);
					} else {
						this.showWarning("Error adding Newspaper, please try again later.");
						throw new Error("Error al agregar Newspaper");
					}
				}
			} catch (error) {
				if (error.message.includes("Plan")) {
					let dataError = error.message;
					dataError = dataError.replace("GraphQL error: ", "");
					this.showWarning(dataError);
				} else {
					console.error(error);
				}
			} finally {
				this.forceAddButtonDisabled = false;
				this.$emit("updateLoading", false);
			}
			/* let DriverToAdd = this.filterNewPaperToAdd();
      this.newspaperAdd = '';
      this.newspaperUrlAdd = '';
      this.newspaperPlaceAdd = '';
      let variables = {
        "context_id": this.formContext.contextId,
        "type": "nws",
        "driver": {
          "id": DriverToAdd.id
        }
      }
      const {
        data: { addKeyword },
      } = await this.$store.dispatch("dashboard/addKeyword", variables);
      this.drivers.push(DriverToAdd);
      if (addKeyword.id) {
        console.log('add', addKeyword);

      } else {
        throw new Error("Error al agregar Fanpage");
      } */
			// this.$emit("driversToAdd", itemToEmit);
		},
		/* filterNewPaperToAdd() {
      let prevList = [];
      let pais_uuid = this.paises.find((p) => p.name == this.newspaperPlaceAdd);
      prevList = this.allNewspaperLoad.filter((p) => p.place_id === pais_uuid.id);
      prevList = prevList.filter((p) => p.newspaper_name === this.newspaperAdd);

      return prevList.find((p) => p.newspaper_Url === this.newspaperUrlAdd);

    }, */
		cleanSelects() {
			this.nwsToAdd = "";
			this.nwsNameSelected = "";
			this.nwsUrlSelected = "";
			this.nwsCountrySelected = "";
			this.fillSelects();
		},
		showWarning: function(message) {
			this.$emit("setWarningMessage", message);
		},
		async deleteDriver(driver) {
			if (
				this.formContext.twitterKeywords.length +
					this.formContext.facebookKeywords.length +
					this.formContext.instagramKeywords.length +
					this.formContext.linkedinKeywords.length +
					this.formContext.newspaperKeywords.length >
				1
			) {
				const deletedDriver = driver;
				const driverIndex = this.drivers.findIndex((d) => {
					return d.id == deletedDriver.id;
				});
				this.drivers.splice(driverIndex, 1);
				if (deletedDriver.id) {
					this.$emit("updateLoading", true);
					const variables = {
						context_id: this.formContext.contextId,
						keyword_id: driver.id,
						type: "nws",
					};
					try {
						const {
							data: { deleteKeyword },
						} = await this.$store.dispatch("dashboard/deleteKeyword", variables);

						if (deleteKeyword != "keyword deleted") {
							this.drivers.splice(driverIndex, 1, driver);
							throw new Error("Error al intentar eliminar el driver!");
						}
					} catch (error) {
						this.drivers.splice(driverIndex, 1, driver);
						console.error(error);
					} finally {
						this.$emit("updateLoading", false);
					}
				}
			} else {
				this.showWarning("Context must have at least one driver");
			}

			// this.$emit("deleteDriversToAdd", driver);
		},
	},
	computed: {
		/* allDrivers: function () {
      return [...this.drivers].sort(
        (a, b) => a.added - b.added
      );
    }, */
	},
	watch: {
		formContext: {
			immediate: true,
			handler: function(x) {
				this.drivers = this.formContext.newspaperKeywords;
				// if (this.newspaperAdd.trim().length > 0) {
				//   this.buttonDisabled = false;
				// } else {
				//   this.buttonDisabled = true;
				// }
			},
		},
	},
};
</script>

<style>
.v-main__fbDriver {
	padding: 0px 0px 0px 0px !important;
}

.w570 {
	width: 470px;
}
</style>
