<template>
	<transition name="modal">
		<div class="modal-mask">
			<div class="modal-wrapper">
				<div class="modal-container">
					<div class="context-content">
						<button
							class="bgColorPrincipal modal-icon-close px-2 py-1 mr-3 mt-3 rounded"
							@click="$emit('close')"
						>
							<v-icon color="#fff" size="16"> fas fa-times </v-icon>
						</button>
						<div class="d-flex justify-center">
							<v-scroll-y-transition>
								<v-alert
									type="error"
									dense
									transition="fab-transition"
									style="position: fixed; max-width: 20em"
									v-if="warningText"
								>
									{{ warningText }}
								</v-alert>
							</v-scroll-y-transition>
						</div>
						<v-row>
							<v-col>
								<h1 class="font18pt titleColor font-weight-bold">Create context</h1>
							</v-col>
						</v-row>

						<v-row>
							<v-col cols="12" class="context-content__search">
								<input
									maxlength="40"
									type="text"
									class="inputs pl-3 font13pt titleColor font-weight-bold"
									placeholder="Context name"
									v-model="name"
								/>
							</v-col>
						</v-row>
						<v-row>
							<div class="component-content__tabs col">
								<v-tabs
									centered
									slider-color="#00A5FF"
									v-model="model"
									background-color="#f3f4f7"
									height="51"
									grow
									class="component-content__tabs-menu"
								>
									<v-tab>
										<span class="font13pt titleColor font-weight-bold text-capitalize mr-1"
											>Twitter</span
										>
										<span>
											<v-icon size="13" color="#1da1f2">fab fa-twitter</v-icon>
										</span>
									</v-tab>
									<v-tab>
										<span class="font13pt titleColor font-weight-bold text-capitalize mr-1"
											>Facebook</span
										>
										<span>
											<v-icon size="13" color="#1877f2">fab fa-facebook</v-icon>
										</span>
									</v-tab>
									<v-tab>
										<span class="font13pt titleColor font-weight-bold text-capitalize mr-1"
											>Instagram</span
										>
										<span>
											<v-icon size="13" color="#ff69a1">fab fa-instagram</v-icon>
										</span>
									</v-tab>
									<v-tab>
										<span class="font13pt titleColor font-weight-bold text-capitalize mr-1"
											>Linkedin</span
										>
										<span>
											<v-icon size="13" color="#0a66c2">fab fa-linkedin</v-icon>
										</span>
									</v-tab>
									<v-tab>
										<span class="font13pt titleColor font-weight-bold text-capitalize mr-1"
											>Newspaper</span
										>
										<span>
											<v-icon size="13" color="#444444">fa-solid fa-newspaper</v-icon>
										</span>
									</v-tab>
								</v-tabs>
								<v-tabs-items v-model="model" class="component-content__tabs__info pb-0">
									<v-tab-item transition="none" reverse-transition="fade-transition">
										<div class="base_content d-flex justify-center align-center">
											<!-- <CreateTwitterDrivers :twDriversToAdd="twDriversToAdd" @driversToAdd="updateTwDriversToAdd"
                        @deleteDriversToAdd="deleteTwDriversToAdd" :allIscDriversToAdd="allIscDriversToAdd"
                        @iscDriversToAdd="updateIscDriversToAdd" @deleteIscDriversToAdd="deleteIscDriversToAdd"
                        @driverLoading="driverLoading" /> -->
											<CreateTwitterDrivers
												:allDriversToAdd="allDriversToAdd.filter((d) => d.type === 'tw')"
												@driverToAdd="driverToAdd"
												@deleteDriverToAdd="deleteDriverToAdd"
												:allIscDriversToAdd="allIscDriversToAdd.filter((d) => d.type === 'tw')"
												@iscDriverToAdd="iscDriverToAdd"
												@deleteIscDriverToAdd="deleteIscDriverToAdd"
												@driverLoading="driverLoading"
												@setWarningMessage="setWarningMessage"
											/>
										</div>
									</v-tab-item>
									<v-tab-item transition="fade-transition" reverse-transition="fade-transition">
										<div class="base_content d-flex justify-center align-center">
											<!-- <CreateFacebookDrivers :fbDriversToAdd="fbDriversToAdd" @driversToAdd="updateFbDriversToAdd"
                        @deleteDriversToAdd="deleteFbDriversToAdd" :allIscDriversToAdd="allIscDriversToAdd"
                        @iscDriversToAdd="updateIscDriversToAdd" @deleteIscDriversToAdd="deleteIscDriversToAdd"
                        @driverLoading="driverLoading" /> -->
											<CreateFacebookDrivers
												:allDriversToAdd="allDriversToAdd.filter((d) => d.type === 'fb')"
												@driverToAdd="driverToAdd"
												@deleteDriverToAdd="deleteDriverToAdd"
												:allIscDriversToAdd="allIscDriversToAdd.filter((d) => d.type === 'fb')"
												@iscDriverToAdd="iscDriverToAdd"
												@deleteIscDriverToAdd="deleteIscDriverToAdd"
												@driverLoading="driverLoading"
												@setWarningMessage="setWarningMessage"
											/>
										</div>
									</v-tab-item>
									<v-tab-item transition="fade-transition" reverse-transition="fade-transition">
										<div class="base_content d-flex justify-center align-center">
											<!-- <CreateInstagramDrivers :igDriversToAdd="igDriversToAdd" @driversToAdd="updateIgDriversToAdd"
                        @deleteDriversToAdd="deleteIgDriversToAdd" :allIscDriversToAdd="allIscDriversToAdd"
                        @iscDriversToAdd="updateIscDriversToAdd" @deleteIscDriversToAdd="deleteIscDriversToAdd"
                        @driverLoading="driverLoading" /> -->
											<CreateInstagramDrivers
												:allDriversToAdd="allDriversToAdd.filter((d) => d.type === 'ig')"
												@driverToAdd="driverToAdd"
												@deleteDriverToAdd="deleteDriverToAdd"
												:allIscDriversToAdd="allIscDriversToAdd.filter((d) => d.type === 'ig')"
												@iscDriverToAdd="iscDriverToAdd"
												@deleteIscDriverToAdd="deleteIscDriverToAdd"
												@driverLoading="driverLoading"
												@setWarningMessage="setWarningMessage"
											/>
										</div>
									</v-tab-item>
									<v-tab-item transition="fade-transition" reverse-transition="fade-transition">
										<div class="base_content d-flex justify-center align-center">
											<!-- <CreateLinkedinDrivers :lkDriversToAdd="lkDriversToAdd" @driversToAdd="updateLkDriversToAdd"
                        @deleteDriversToAdd="deleteLkDriversToAdd" :allIscDriversToAdd="allIscDriversToAdd"
                        @iscDriversToAdd="updateIscDriversToAdd" @deleteIscDriversToAdd="deleteIscDriversToAdd"
                        @driverLoading="driverLoading" /> -->
											<CreateLinkedinDrivers
												:allDriversToAdd="allDriversToAdd.filter((d) => d.type === 'lk')"
												@driverToAdd="driverToAdd"
												@deleteDriverToAdd="deleteDriverToAdd"
												:allIscDriversToAdd="allIscDriversToAdd.filter((d) => d.type === 'lk')"
												@iscDriverToAdd="iscDriverToAdd"
												@deleteIscDriverToAdd="deleteIscDriverToAdd"
												@driverLoading="driverLoading"
												@setWarningMessage="setWarningMessage"
											/>
										</div>
									</v-tab-item>
									<v-tab-item transition="fade-transition" reverse-transition="fade-transition">
										<!-- <div class="base_content d-flex justify-center align-center">
                      <CreateNewsPaperDrivers :nwsDriversToAdd="nwsDriversToAdd" @driversToAdd="updateNewspaperToAdd"
                        @deleteDriversToAdd="deleteNwsDriversToAdd" @driverLoading="driverLoading" />
                    </div> -->
										<div class="base_content d-flex justify-center align-center">
											<CreateNewsPaperDrivers
												:allDriversToAdd="allDriversToAdd.filter((d) => d.type === 'nws')"
												@driverToAdd="driverToAdd"
												@deleteDriverToAdd="deleteDriverToAdd"
												@driverLoading="driverLoading"
												@setWarningMessage="setWarningMessage"
											/>
										</div>
									</v-tab-item>
								</v-tabs-items>
							</div>
						</v-row>
						<v-row>
							<v-col class="text-right">
								<button class="buttonSave pt-2 pb-2 pl-5 pr-5 ml-3 rounded" @click="saveContext()">
									<span class="mr-1 font13pt">{{ loading ? "Saving..." : "Save" }}</span>
									<v-icon color="#fff" size="13" v-if="!loading"> fas fa-save </v-icon>
								</button>
							</v-col>
						</v-row>
					</div>
					<div class="prog-bar__createContext">
						<v-progress-linear indeterminate v-if="loading || checkDriverLoading"></v-progress-linear>
					</div>
				</div>
			</div>
		</div>
	</transition>
</template>

<script>
import CreateTwitterDrivers from "@/components/context/CreateTwitterDrivers.vue";
import CreateFacebookDrivers from "@/components/context/CreateFacebookDrivers.vue";
import CreateInstagramDrivers from "@/components/context/CreateInstagramDrivers.vue";
import CreateLinkedinDrivers from "@/components/context/CreateLinkedinDrivers.vue";
import CreateNewsPaperDrivers from "@/components/context/CreateNewspaperDrivers.vue";

export default {
	name: "CreateContext",
	props: ["contentModal"],
	components: {
		CreateTwitterDrivers,
		CreateFacebookDrivers,
		CreateInstagramDrivers,
		CreateLinkedinDrivers,
		CreateNewsPaperDrivers,
	},
	data() {
		return {
			model: "",
			name: "",
			warningText: "",
			loading: false,
			checkDriverLoading: false,
			ISCDrivers: [],
			// buttonDisabled: true,
			twDriversToAdd: [],
			fbDriversToAdd: [],
			igDriversToAdd: [],
			lkDriversToAdd: [],
			nwsDriversToAdd: [],

			allIscDriversToAdd: [],
			allDriversToAdd: [],
		};
	},
	created() {
		//this.setISCDrivers();
	},
	methods: {
		driverToAdd(driver) {
			this.allDriversToAdd.push(driver);
		},
		iscDriverToAdd(driver) {
			this.allIscDriversToAdd.push(driver);
		},
		deleteIscDriverToAdd(addedDriver, attr) {
			this.allIscDriversToAdd = this.allIscDriversToAdd.filter((item) => item[attr] !== addedDriver);
			// this.validateInputs();
		},
		deleteDriverToAdd(addedDriver, attr) {
			this.allDriversToAdd = this.allDriversToAdd.filter((item) => item[attr] !== addedDriver);
			// this.validateInputs();
		},
		saveContext: async function () {
			const validated = this.validateInputs();
			if (!validated) {
				return;
			}
			if (this.allDriversToAdd.length > 0 || this.allIscDriversToAdd.length > 0) {
				this.loading = true;
				const tw_drivers_array = this.allDriversToAdd
					.filter((d) => d.type === "tw")
					.map((driver) => {
						return {
							active: driver.active,
							place_id: driver.place_id,
							term: driver.term,
						};
					});
				const fb_drivers_array = this.allDriversToAdd
					.filter((d) => d.type === "fb")
					.map((driver) => {
						return {
							active: driver.active,
							mask: driver.mask,
							name: driver.name,
							page_id: driver.page_id,
							page_url: driver.page_url,
						};
					});
				const ig_drivers_array = this.allDriversToAdd
					.filter((d) => d.type === "ig")
					.map((driver) => {
						return {
							active: driver.active,
							mask: driver.mask,
							name: driver.name,
							page_id: driver.page_id,
							page_url: driver.page_url,
						};
					});
				const lk_drivers_array = this.allDriversToAdd
					.filter((d) => d.type === "lk")
					.map((driver) => {
						return {
							active: driver.active,
							mask: driver.mask,
							name: driver.name,
							page_id: driver.page_id,
							page_url: driver.page_url,
						};
					});
				const nws_drivers_array = this.allDriversToAdd
					.filter((d) => d.type === "nws")
					.map((driver) => {
						return {
							id: driver.id,
							active: driver.active,
						};
					});
				const variablesCreateContext = {
					account_id: sessionStorage.getItem("client_id"),
					name: this.name,
					twitter_drivers: tw_drivers_array.length ? tw_drivers_array : null,
					facebook_drivers: fb_drivers_array.length ? fb_drivers_array : null,
					instagram_drivers: ig_drivers_array.length ? ig_drivers_array : null,
					linkedin_drivers: lk_drivers_array.length ? lk_drivers_array : null,
					newspapers_drivers: nws_drivers_array.length ? nws_drivers_array : null,
				};

				try {
					const response = await this.$store.dispatch("overview/createContext", variablesCreateContext);
          if (response.error) {
						throw new Error(response.error);
					} else {
						if (response.data.createContext.id) {
							if (this.allIscDriversToAdd.length > 0) {
								for (const iscDriver of this.allIscDriversToAdd) {
									await this.$store.dispatch("overview/addGeoKeyword", {
										context_id: response.data.createContext.id,
										keyword_id: iscDriver.id,
										active: iscDriver.active,
										type: iscDriver.type,
									});
								}
							}
						} else {
							throw new Error("Error creating context");
						}
					}
					this.loading = false;
					this.$emit("close", response.data.createContext);
				} catch (error) {
          console.log("error: ", error);
					if (error.message.includes("Plan")) {
						let dataError = error.message;
						dataError = dataError.replace("GraphQL error: ", "");
						this.setWarningMessage(dataError);
					} else {
						this.setWarningMessage(error);
					}
					return;
				}
			}
		},
		setWarningMessage(message) {
			this.warningText = message;
			setTimeout(() => {
				this.warningText = "";
				sessionStorage.removeItem("errorMessage");
			}, 6000);
      this.loading = false;
		},
		validateInputs() {
			if (this.allDriversToAdd.length > 0 || this.allIscDriversToAdd.length > 0) {
				if (this.name.trim().length <= 0) {
					// this.buttonDisabled = false;
					this.setWarningMessage("Please enter a name for the context");
					return false;
				}
				return true;
			} else {
				// this.buttonDisabled = true;
				this.setWarningMessage("Please add at least one driver");
				return false;
			}
		},
		driverLoading(value) {
			this.checkDriverLoading = value;
		},
	},
	watch: {
		/* ISCDrivers: {
      deep: true,
      handler: (x) => {
        if (this.ISCDrivers.length > 0) {
          return false;
        } else {
          return true;
        }
      },
    }, */
		// allDriversToAdd: {
		//   immediate: true,
		//   deep: true,
		//   handler: function () {
		//     //console.log("allDriversToAdd", this.allDriversToAdd);
		//     this.validateInputs();
		//   },
		// },
		// allIscDriversToAdd: {
		//   immediate: true,
		//   deep: true,
		//   handler: function () {
		//     //console.log("allIscDriversToAdd", this.allIscDriversToAdd);
		//     this.validateInputs();
		//   },
		// },
		/* twDriversToAdd: {
      immediate: true,
      handler: function () {
        this.validateInputs();
      },
    },
    fbDriversToAdd: {
      immediate: true,
      handler: function () {
        this.validateInputs();
      },
    },
    igDriversToAdd: {
      immediate: true,
      handler: function () {
        this.validateInputs();
      },
    },
    lkDriversToAdd: {
      immediate: true,
      handler: function () {
        this.validateInputs();
      },
    }, */
		// name: {
		//   immediate: true,
		//   handler: function () {
		//     this.validateInputs();
		//   },
		// },
	},
};
</script>

<style>
.prog-bar__createContext {
	width: 100%;
	bottom: 0px;
	left: 0px;
	position: absolute;
}

.component-content__tabs__info {
	padding: 0px !important;
}
</style>
