<template>
	<div class="context-box" style="width: 90%">
		<v-row no-gutters>
			<v-col :sm="12" :md="12" :lg="12">
				<v-row no-gutters>
					<v-col cols="12"><span class="font12pt leyendColor">What:</span></v-col>
				</v-row>
			</v-col>
			<v-col :sm="6" :md="7" :lg="8">
				<v-row>
					<v-col cols="12" class="pr-1 pl-1 pt-1">
						<input
							maxlength="80"
							type="text"
							class="input pl-3 font12pt titleColor font-weight-bold w-100"
							placeholder="Paste Url Fan Page..."
							v-model="newDriverPage"
						/>
						<!-- <div class="pt-1 textError ml-0" v-if="warningText != ''">
              <span><v-icon size="12" color="red">fa fa-exclamation-circle</v-icon> {{ warningText }}</span>
            </div> -->
					</v-col>
				</v-row>
			</v-col>
			<v-col :sm="6" :md="5" :lg="4">
				<div class="d-flex justify-end align-end">
					<v-btn
						depressed
						color="bgColorPrincipal"
						class="mainBtn mx-2"
						:disabled="buttonDisabled"
						@click="addNewDriver()"
					>
						<span class="white--text">Create</span>
					</v-btn>
					<v-btn depressed color="bgColorPrincipal" class="mainBtn" @click="openModal = true">
						<span class="white--text mr-1" style="font-size: 20px">+</span
						><span class="white--text"> ISC Drivers</span>
					</v-btn>
				</div>
			</v-col>
			<v-row class="context-box__table">
				<v-col>
					<div class="context-box__table__header d-flex">
						<div class="col-12">
							<span class="font12pt leyendColor">Fan Page list</span>
						</div>
					</div>
					<div class="bgColorBase context-box__table__body">
						<v-virtual-scroll :bench="10" :items="fbDrivers" max-height="168" item-height="42">
							<template v-slot:default="{ item }">
								<v-row
									v-bind:style="{
										backgroundColor: item.geo_dashboard ? '#fbfbfb' : 'inherit',
									}"
								>
									<v-col cols="1" align="end">
										<div>
											<v-tooltip top>
												<template v-slot:activator="{ on, attrs }">
													<v-icon
														v-if="item.geo_dashboard"
														size="14"
														color="#a2acc4"
														v-bind="attrs"
														v-on="on"
														>fas fa-globe</v-icon
													>
												</template>
												<span>ISC Driver</span>
											</v-tooltip>
										</div>
									</v-col>
									<v-col cols="5">
										<div style="overflow: hidden; white-space: nowrap; text-overflow: ellipsis">
											<span class="font12pt titleColor ml-10">
												{{ item.name }}
											</span>
										</div>
									</v-col>
									<v-col cols="4">
										<div style="overflow: hidden; white-space: nowrap; text-overflow: ellipsis">
											<span class="font12pt leyendColor">
												{{ `${item.mask}` }}
											</span>
											<span class="font12pt leyendColor" v-if="item.likes">
												{{ ` (${item.likes} likes)` }}
											</span>
										</div>
									</v-col>
									<v-col cols="1" align="start">
										<div>
											<v-icon @click="deleteKeyword(item)" size="11" color="#a2acc4"
												>fas fa-trash</v-icon
											>
										</div>
									</v-col>
									<v-col cols="1" align="start">
										<div>
											<v-icon
												v-if="item.active === true"
												size="12"
												color="#00a5ff"
												@click="toggleActive(item, false)"
											>
												fas fa-eye</v-icon
											>
											<v-icon
												v-if="item.active === false"
												size="12"
												color="#a2acc4"
												@click="toggleActive(item, true)"
											>
												fas fa-eye</v-icon
											>
										</div>
									</v-col>
								</v-row>
							</template>
						</v-virtual-scroll>
					</div>
				</v-col>
			</v-row>
		</v-row>
		<AddIscDriverModal
			v-if="openModal"
			@closeIscDriverModal="closeIscDriverModal"
			:ISCDrivers="ISCDrivers"
			:driverType="'fb'"
		/>
	</div>
</template>

<script>
import AddIscDriverModal from "@/components/context/AddIscDriverModal.vue";

export default {
	name: "UpdateFacebookDrivers",
	props: ["formContext", "UpdateISCDrivers"],
	components: { AddIscDriverModal },
	data() {
		return {
			fbDrivers: [],
			newDriverPage: "",
			statusActive: false,
			openModal: false,
			buttonDisabled: true,
			paises: [
				{
					id: "all",
					name: "All (Around the world)",
				},
				{
					id: "2dfa9ecb0179a4e4",
					name: "Perú",
				},
				{
					id: "47a3cf27863714de",
					name: "Chile",
				},
			],
			loading: false,
		};
	},
	created() {
		this.fbDrivers = this.formContext.facebookKeywords;
		this.fbDrivers = this.fbDrivers.filter((d) => d.type == "fb");
	},
	methods: {
		addNewDriver: async function() {
			this.buttonDisabled = true;
			const urlRegEx = /https?:\/\/(www\.)?[-a-zA-Z0-9@:%._+~#=]{1,256}\.[a-zA-Z0-9()]{1,6}\b([-a-zA-Z0-9()@:%_+.~#?&//=]*)/g;
			if (!this.newDriverPage || !this.newDriverPage.match(urlRegEx)) {
				this.showWarning("The URL provided is not a valid fanpage");
				return;
			}
			try {
				await this.sendUrlCheck();
			} catch (error) {
				if (error.message.includes("Plan")) {
					let dataError = sessionStorage.getItem("errorMessage");
					dataError = dataError.replace("GraphQL error: ", "");
					this.showWarning(dataError);
				} else {
					if (this.newDriverPage.includes("-")) {
						const splitUrl = this.newDriverPage.split("-");
						const pageId = splitUrl[splitUrl.length - 1];
						this.newDriverPage = `https://www.facebook.com/${pageId}`;
						try {
							await this.sendUrlCheck();
						} catch (error) {
							this.showWarning("The URL provided is not a valid fanpage");
						}
					} else {
						this.showWarning("The URL provided is not a valid fanpage");
					}
				}
			}
		},
		sendUrlCheck: async function() {
			try {
				this.activeLoading(true);
				const {
					data: { fbFanpageCheck },
				} = await this.$store.dispatch("dashboard/fbFanpageCheck", {
					fanpage_url: this.newDriverPage.trim(),
				});
				if (fbFanpageCheck && fbFanpageCheck.public === true) {
					const newDriver = {
						page_url: this.newDriverPage.trim(),
						page_id: fbFanpageCheck.id,
						mask: fbFanpageCheck.mask,
						name: fbFanpageCheck.name,
						active: true,
					};
					if (this.fbDrivers.some((d) => d.page_id == newDriver.page_id)) {
						this.showWarning("Fanpage already added");
					} else {
						const variables = {
							context_id: this.formContext.contextId,
							driver: newDriver,
							active: newDriver.active,
							type: "fb",
						};
						try {
							const response = await this.$store.dispatch("dashboard/addKeyword", variables);
							if (response.error) {
								throw new Error(response.error);
							} else {
								if (response.data.addKeyword.id) {
									this.fbDrivers.push(response.data.addKeyword);
									this.newDriverPage = "";
								}
							}
						} catch (error) {
							if (error.message.includes("Plan")) {
								let dataError = error.message;
								dataError = dataError.replace("GraphQL error: ", "");
								this.showWarning(dataError);
							} else {
								this.showWarning(error);
							}
						}
					}
				} else {
					throw new Error("Error validating Fanpage");
				}
			} catch (error) {
				console.error(error);
				throw error;
			} finally {
				this.activeLoading(false);
			}
		},

		async toggleActive(item, status) {
			this.activeLoading(true);
			if (item.id) {
				const variables = {
					context_id: this.formContext.contextId,
					keyword_id: item.id,
					type: "fb",
					active: status,
				};
				try {
					const {
						data: { toggleActiveKeyword },
					} = await this.$store.dispatch("dashboard/toggleActiveKeyword", variables);
					if (toggleActiveKeyword[0].active === status) {
						this.fbDrivers = this.fbDrivers.map(function(k) {
							if (k.id == item.id) {
								k.active = status;
							}
							return k;
						});
					}
				} catch (error) {
					let dataError = error.message;
					dataError = dataError.replace("GraphQL error: ", "");
					this.showWarning(dataError);
					console.error(error);
				} finally {
					this.activeLoading(false);
				}
			}
		},
		async deleteKeyword(item) {
			if (
				this.formContext.twitterKeywords.length +
					this.formContext.facebookKeywords.length +
					this.formContext.instagramKeywords.length +
					this.formContext.linkedinKeywords.length +
					this.formContext.newspaperKeywords.length >
				1
			) {
				this.activeLoading(true);
				if (item.id) {
					const variables = {
						context_id: this.formContext.contextId,
						keyword_id: item.id,
						type: "fb",
					};
					try {
						const {
							data: { deleteKeyword },
						} = await this.$store.dispatch("dashboard/deleteKeyword", variables);

						if (deleteKeyword == "keyword deleted") {
							this.fbDrivers = this.fbDrivers.filter((k) => k.id !== item.id);
						} else {
							throw new Error("Error deleting keyword");
						}
					} catch (error) {
						console.error(error);
					} finally {
						this.activeLoading(false);
					}
				}
			} else {
				this.showWarning("Context must have at least one driver");
			}
		},
		async closeIscDriverModal(driver) {
			this.openModal = false;
			if (driver) {
				if (this.fbDrivers.some((d) => d.id == driver.id)) {
					this.showWarning("Driver already added");
				} else {
					this.activeLoading(true);
					const variables = {
						context_id: this.formContext.contextId,
						keyword_id: driver.id,
						active: true,
						type: "fb",
					};
					try {
						const {
							data: { addGeoKeyword },
						} = await this.$store.dispatch("dashboard/addGeoKeyword", variables);
						if (addGeoKeyword.id) {
							addGeoKeyword.active = true;
							this.fbDrivers.push(addGeoKeyword);
						} else {
							throw new Error("Error attempting to add driver");
						}
					} catch (error) {
						console.error(error);
					} finally {
						this.activeLoading(false);
					}
				}
			}
		},
		showWarning(message) {
			this.$emit("setWarningMessage", message);
		},
		activeLoading(value) {
			this.$emit("updateLoading", value);
			if (value === true) {
				this.buttonDisabled = true;
				this.loading = true;
			} else {
				this.loading = false;
				this.checkPage();
			}
		},
		checkPage() {
			if (this.newDriverPage.trim().length > 0) {
				if (this.loading === true) {
					this.buttonDisabled = true;
				} else {
					this.buttonDisabled = false;
				}
			} else {
				this.buttonDisabled = true;
			}
		},
	},
	watch: {
		newDriverPage: {
			immediate: true,
			handler: function(x) {
				this.checkPage();
			},
		},
	},
};
</script>

<style>
.v-main__updateFbDriver {
	padding: 0px 0px 0px 0px !important;
}
</style>
